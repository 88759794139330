import {getGalleryImages} from './../api/ajax.js';

const onProjectMoreInformationClicked = (e) => {
  $(e.currentTarget).closest('.project-item')
    .find('.js-information-target')
    .slideToggle();
};

const onGalleryImagesRetrieved = (data) => {
  let images = [];
  for (let i = 0; i < data.length; i++) {
    images.push({
      src: data[i].url
    });
  }
  $.magnificPopup.open({
    items: images,
    gallery: {
      enabled: true
    },
    type: 'image'
  });
};

const onGalleryImageRequestFailed = () => {
  console.error('Image request failed.');
};

const onGalleryButtonClicked = (e) => {
  let projectSlug = $(e.currentTarget).attr('data-slug');
  getGalleryImages(projectSlug, onGalleryImagesRetrieved, onGalleryImageRequestFailed);
};

document.addEventListener('load', () => {
  const $itProjects = $('#it-projects');
  if ($itProjects.length === 0) {
    return;
  }

  $('.js-information-target').hide();
  $('.js-information').on('click', onProjectMoreInformationClicked);
  $('.js-gallery').on('click', onGalleryButtonClicked);
});
