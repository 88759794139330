import Cookies from 'js-cookie';

const csrfToken = Cookies.get('csrftoken');
const csrfSafeMethod = (method) => {
  return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
};

const ajaxSetup = () => {
  $.ajaxSetup({
    beforeSend: function (xhr, settings) {
      if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
        xhr.setRequestHeader("X-CSRFToken", csrfToken);
      }
    }
  });
};

const getGalleryImages = (projectSlug, onSuccess, onError) => {
  ajaxSetup();

  $.ajax(`/site/it-projects/${projectSlug}/`, {
    success: onSuccess,
    error: onError,
    method: 'GET'
  });
};

const getNipponGalleryImages = (page, onSuccess, onError) => {
    ajaxSetup();

    $.ajax(`/ajax/nippon-photos/${page}/`, {
        success: onSuccess,
        error: onError,
        method: 'GET'
    });
} ;

const getNipponGalleryImage = (slug, onSuccess) => {
    ajaxSetup();

    $.ajax(`/ajax/nippon-photo/${slug}/`, {
        success: onSuccess,
        method: 'GET'
    });
} ;

export {getGalleryImages, getNipponGalleryImages, getNipponGalleryImage};
