import $ from 'jquery';

export class InfiniteScroll {
  constructor($loader, endPoint, $targetContainer, onItemsAddedCbk) {
    this.$loader = $loader;
    this.endPoint = endPoint;
    this.$targetContainer = $targetContainer;
    this.onItemsAddedCbk = onItemsAddedCbk;
    this.page = 1;
    this.isPageRequested = false;
    this.isEnabled = true;

    $($loader).on('click', this.loadMoreImages.bind(this));
  }

  onRequestSuccess(response) {
    this.$loader.show();
    this.$targetContainer.append(response);
    this.onItemsAddedCbk();
    this.isPageRequested = false;
  }

  onRequestFailed() {
    this.$loader.remove();
    this.isEnabled = false;
  }

  loadMoreImages() {
    if (!this.isPageRequested && this.isEnabled) {
      this.$loader.hide();
      this.isPageRequested = true;
      this.endPoint(++this.page, this.onRequestSuccess.bind(this), this.onRequestFailed.bind(this));
    }
  }
}
