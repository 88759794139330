import $ from 'jquery';

const
  SCREEN_SMALL = 768,
  SCREEN_MEDIUM = 992,
  SCREEN_LARGE = 1200;

export class PhotoGallery {
  constructor($gallery) {
    this.$gallery = $gallery;
    this.images = 0;
    this.mode = 0;
    this.windowWidth = window.innerWidth;

    $(window).on('resize', this.onWindowResized.bind(this));
    this.onWindowResized();
  }

  calculateImagesPerRow() {
    if (this.windowWidth > SCREEN_LARGE) {
      this.images = 3;
    } else if (this.windowWidth > SCREEN_MEDIUM) {
      this.images = 2;
    } else if (this.windowWidth > SCREEN_SMALL) {
      this.images = 2;
    } else {
      this.images = 1;
    }
  }

  onWindowResized() {
    this.windowWidth = window.innerWidth;
    this.calculateImagesPerRow();
    let newPadding = this.$gallery.innerWidth() - ((300 + 10) * this.images);
    newPadding = parseInt(newPadding / 2);

    this.$gallery.css({
      'padding-left': newPadding,
      'padding-right': newPadding
    });
  }
}
