import {debounce} from './../atoms/debouncer.js';

const MENU_CLASS = 'menu';
const MENU_LINKS_CLASS = 'menu__links';
const MENU_BUTTON_CLASS = 'js-btn-menu';
const MENU_OPENED_MODIFIER = 'menu--show';
const MENU_LINKS_SHOW_MODIFIER = 'menu__links--show';
const MENU_TOP_MODIFIER = 'menu--top';

const TOP_MENU_HEIGHT = 130;

class Menu {
  constructor() {
    this.navigationBar = document.querySelector(`.${MENU_CLASS}`);
    this.navigationBarLinks = document.querySelector(`.${MENU_LINKS_CLASS}`);
    this.navigationBarButton = document.querySelector(`.${MENU_BUTTON_CLASS}`);

    this.navigationBarYPosition = window.scrollY;
    this.navigationBarLastScrollPosition = window.scrollY;
    this.isMenuOpened = false;
    this.isMenuFixed = false;

    this.bindEvents();
  }

  bindEvents() {
      // Adjust the navigationBar when scrolling
      window.addEventListener('scroll', debounce(this.adjustNavigationBar.bind(this), 50));

      // Open/Collapse responsive menu
      this.navigationBarButton.addEventListener('click', this.onMenuButtonClicked.bind(this));
  
      // Close the menu if the user is resizing the page
      window.addEventListener('resize', debounce(this.onWindowResize.bind(this), 200));
  }

  onMenuButtonClicked() {  
    if (this.isMenuOpened) {
      this.navigationBarLinks.classList.remove(MENU_LINKS_SHOW_MODIFIER);
      this.navigationBar.classList.remove(MENU_OPENED_MODIFIER);
    } else {
      this.navigationBarLinks.classList.add(MENU_LINKS_SHOW_MODIFIER);
      this.navigationBar.classList.add(MENU_OPENED_MODIFIER);
    }

    this.isMenuOpened = !this.isMenuOpened;
  };

  onWindowResize() {
    // Close menu if the client has resized the window while is opened
    if (window.matchMedia('(min-width: 762px)').matches) {
      if (!this.isMenuOpened) {
        return;
      }

      this.navigationBarLinks.classList.remove(MENU_LINKS_SHOW_MODIFIER);
      this.navigationBar.classList.remove(MENU_OPENED_MODIFIER);
    }
  };

  adjustNavigationBar() {
    this.navigationBarYPosition = window.scrollY;
    const isClientOnTopOfPage = TOP_MENU_HEIGHT >= window.scrollY;

    if (isClientOnTopOfPage) {
      if (this.isMenuFixed) {
        this.setMenuNotFixed();
      }
      this.navigationBarLastScrollPosition = this.navigationBarYPosition;

      return;
    }

    const isClientScrollingUp = this.navigationBarLastScrollPosition >= this.navigationBarYPosition - 5;
    if (isClientScrollingUp) {
      this.setMenuFixed();
    } else {
      this.setMenuNotFixed();
    }

    this.navigationBarLastScrollPosition = this.navigationBarYPosition;
  };

  setMenuNotFixed() {
    if (!this.isMenuFixed) {
      return;
    }

    this.navigationBar.classList.remove(MENU_TOP_MODIFIER);
    this.isMenuFixed = false;
  }

  setMenuFixed() {
    if (this.isMenuFixed) {
      return;
    }

    this.navigationBar.classList.add(MENU_TOP_MODIFIER);
    this.isMenuFixed = true;
  }
}

window.onload = () => {
  new Menu();
};
