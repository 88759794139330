import {getNipponGalleryImages, getNipponGalleryImage} from './../api/ajax.js';
import {InfiniteScroll} from './../components/infinite_scroll.js';
import $ from 'jquery';

const onImageClicked = (evt) => {
  const imageIdentifier = $(evt.currentTarget).attr('data-identifier');
  getNipponGalleryImage(imageIdentifier, onImageRetrieved);
};

const onImageRetrieved = (response) => {
  const
    url = response.url,
    description = response.description;
  $.magnificPopup.open({
    items: {
      src: url,
      title: description
    },
    type: 'image',
  });
};

const bindPhotoOnClick = () => {
  $('.photo-gallery__image')
    .on('click', null)
    .on('click', onImageClicked);
};

$(document).ready(function () {
  const 
    $loader = $('.js-infinite-scroll'),
    $gallery = $('.photo-gallery__wrapper');
  if ($loader.length === 0 || $gallery.length === 0) {
    return;
  }
  new InfiniteScroll($loader, getNipponGalleryImages, $gallery, bindPhotoOnClick);
  bindPhotoOnClick();
});
